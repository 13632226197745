import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Fade from 'react-reveal/Fade'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Footer from '../components/Footer'

const footerLinks = ['traceability', 'pot-fishing', 'past']

const ContactPage = ({ location, data }) => {
  const page = data.prismicSettings.data
  return (
    <Layout>
      <SEO
        title={data.prismicContact.data.meta_title}
        description={data.prismicContact.data.meta_description}
        location={location}
      />

      <div className="gutters pt-hh+">
        <div className="relative">
          <div className="w-full lg:w-20/24 relative z-10">
            <div className="flush-both lg:flush-left">
              <Fade delay={225} duration={2250}>
                <Img
                  className="h-full w-full"
                  fluid={data.heroImage.childImageSharp.fluid}
                  objectFit="cover"
                  objectPosition="50% 50%"
                />
              </Fade>
            </div>
          </div>
          <div className="relative lg:absolute right-0 inset-y-0 lg:w-8/24 -mt-24 lg:mt-0 z-20">
            <div className="h-full flex items-center">
              <div className="w-full bg-blue-800">
                <Fade delay={450} duration={2250}>
                  <div className="mx-1/12 lg:mx-1/8 py-10 lg:py-18">
                    <h1 className="type-upper-xs leading-tight mb-6">
                      Contact
                    </h1>
                    <div className="lg:text-2xl font-light">
                      <h2 className="mb-5">Okains Bay Seafood Ltd</h2>
                      {page.postal_address.html && (
                        <div
                          className="mb-5"
                          dangerouslySetInnerHTML={{
                            __html: page.postal_address.html,
                          }}
                        ></div>
                      )}
                      {page.phone_number && (
                        <div>
                          p.{' '}
                          <a
                            href={`tel:${page.phone_number.replace(/\s/g, '')}`}
                          >
                            {page.phone_number}
                          </a>
                        </div>
                      )}
                      {page.email_address && (
                        <div>
                          e.{' '}
                          <a href={`mailto:${page.email_address}`}>
                            {page.email_address}
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer links={footerLinks} />
    </Layout>
  )
}

ContactPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export const pageFutureQuery = graphql`
  query {
    heroImage: file(relativePath: { eq: "hero/contact.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    prismicContact {
      data {
        meta_description
        meta_title
      }
    }
    prismicSettings {
      data {
        email_address
        phone_number
        postal_address {
          html
          text
        }
      }
    }
  }
`

export default ContactPage
